import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import ProjectLink from "../components/ProjectLink"

const PersonPage = ({ data }) => {
  const { allProjectsQuery, personQuery } = data
  const allProjects = allProjectsQuery["edges"]
  const person = personQuery["frontmatter"]
  const [projects, setProjects] = useState([])

  const imageStyle = { borderRadius: "10px", maxWidth: 300 }

  // run the effect only once, when the page loads
  useEffect(() => {
    // filter all projects for ones that contain this person
    const projectsFilter = () => {
      const results = allProjects.filter(project =>
        project.node.frontmatter.people.includes(person.name)
      )
      console.log({ results })
      setProjects(results)
    }
    projectsFilter()
  }, [allProjects, person.name])

  return (
    <Layout>
      <div>{person.name}</div>
      <div>{person.role}</div>
      <Img style={imageStyle} fluid={person.image.childImageSharp.fluid} />
      <div>{person.biography}</div>
      {projects.length > 0 && <div>Projects: </div>}
      {projects.length > 0 &&
        projects.map((project, i) => (
          <ProjectLink key={i} project={project.node} />
        ))}
    </Layout>
  )
}

export default PersonPage

// String! implies non-nullable
// We are guaranteed an id because once was assigned on page creation
export const pageQuery = graphql`
  query PersonByID($id: String!) {
    personQuery: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        name
        role
        biography
        image {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
    allProjectsQuery: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "project-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            name
            people
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
